import {Card} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import React from "react";

const TablaRentabilidad = (props) => {

    const columns_resumen = [{
        dataField: 'id',
        text: '#'
    },{
        dataField: 'fecha',
        text: 'Fecha',
        sort: true
    }, {
        dataField: 'pesosAcumuladosComparar',
        text: `Dinero acumulado en pesos a la fecha AFP ${props.afp}`
    }, {
        dataField: 'pesosAcumuladosModelo',
        text: 'Dinero acumulado en pesos a la fecha AFP Modelo'
    }, {
        dataField: 'rentabilidadComparar',
        text: `Rentabilidad en AFP ${props.afp}`
    }, {
        dataField: 'rentabilidadModelo',
        text: 'Rentabilidad en AFP Modelo'
    }, {
        dataField: 'flujoMensualComparar',
        text: `Aporte Mensual 10% AFP ${props.afp}`
    }, {
        dataField: 'flujoMensualModelo',
        text: 'Aporte Mensual 10% AFP Modelo'
    }
    ]

    const datosRentabilidadAfp = props.datosRentabilidadAfp !== undefined && Array.from(props.datosRentabilidadAfp);
    
    datosRentabilidadAfp.sort(orden_ascendente);
    
    const datos_resumen = datosRentabilidadAfp.map((cuota, index) => {
        return {
            id: index,
            fecha: cuota.fecha,
            rentabilidadModelo: cuota.rentabilidadModelo,
            rentabilidadComparar: cuota.rentabilidadComparar,
            flujoMensualModelo: cuota.flujoMensualModelo,
            flujoMensualComparar: cuota.flujoMensualComparar,
            pesosAcumuladosModelo: cuota.pesosAcumuladosModelo,
            pesosAcumuladosComparar: cuota.pesosAcumuladosComparar
        }
    });

    return (
        <Card className={"mx-auto w-94 mt-5"}>
            <Card.Body>
                <Card.Title>Detalle Resultados Rentabilidad AFPs</Card.Title>
                <BootstrapTable
                    bootstrap4
                    keyField='id'
                    data={ datos_resumen }
                    columns={ columns_resumen }
                    striped
                    hover
                    condensed
                    pagination={ paginationFactory()}
                />

            </Card.Body>
        </Card>
    )
}

export default TablaRentabilidad;

function orden_ascendente(objectoA, objectoB) {
    const auxA = new Date(objectoA.fecha);
    const auxB = new Date(objectoB.fecha);
    return auxB > auxA ? 1: -1
}