import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/img/Logo - Positivo.svg";
import configuration from "../Config";

const Footer = () => {
	const now = new Date();

	const year = now.getUTCFullYear();
	const month = now.getUTCMonth();
	function getMonthName(monthNumber) {
		const date = new Date();
		date.setMonth(monthNumber);
	  
		return date.toLocaleString('es-ES', {
		  month: 'long',
		});
	  }
	const [datosPrevisionales, setDatosPrevisionales] = useState({});
	useEffect(() => {
		const obtenerDatosPrevisionales = async () => {
			const response = await axios.get(`${configuration.API_URL}/api/afp/Comisiones`);
			const resultado = response.data;

			setDatosPrevisionales({
				mes: resultado["mes"],
				anio: resultado["anio"],
				capital: resultado["capital"],
				cuprum: resultado["cuprum"],
				habitat: resultado["habitat"],
				modelo: resultado["modelo"],
				planvital: resultado["planvital"],
				provida: resultado["provida"],
				uno: resultado["uno"],
			});
		};
		obtenerDatosPrevisionales();
	}, []);

	return (
		<footer className="footer">
			<div className="container-footer">
				<img
					className="logo-footer"
					src={logo}
					alt="logo-afp-modelo"
					width="200"
				/>
				<p>
					AFP Modelo no se hace cargo de la veracidad de los datos que ingrese
					el usuario a esta calculadora. El sistema calcula la diferencia entre
					las comisiones de Modelo y las demás AFPs vigentes según periodo,
					aplicando la tabla de impuesto y tope imponible: Aporte a AFP (10%),
					Aporte de Salud (7%) comisión de AFP y Seguro Cesantía (0,6%). Los
					cálculos son: (A) Aportes que el usuario habría realizado según sueldo
					líquido ingresado, durante el periodo indicado en su cuenta
					obligatoria en AFP a comparar y AFP Modelo (B) El total del aporte se
					actualiza al valor cuota del fondo al último día hábil del mes
					anterior al momento de la realización del cálculo para el cálculo de
					rentabilidad, tanto en la AFP a comparar como en Modelo (C) Para
					calcular el ahorro en comisión, se resta entre la comisión mensual en
					la AFP a comparar y la comisión de AFP Modelo; para cada mes. La suma
					total de estos diferenciales es el ahorro en Comisión (D) En caso que
					la resta del punto anterior sea un número positivo, utiliza ese valor
					y simula un aporte en APV en régimen A (con un 15% de bonificación
					fiscal), y presenta al usuario cuál sería su ahorro adicional
					actualizado al valor cuota del fondo seleccionado al último día hábil
					del mes anterior al momento del cálculo. La rentabilidad es variable,
					por lo que nada garantiza que las rentabilidades pasadas se repitan en
					el futuro. Infórmese sobre la rentabilidad de su Fondo de Pensiones,
					las comisiones y la calidad de servicio de las AFP en el sitio web de
					la Superintendencia de Pensiones{" "}
					<a href="https://www.spensiones.cl">www.spensiones.cl </a> Estructura
					de comisiones por depósitos de cotizaciones vigentes a{" "}
					{getMonthName(month)} de {year}- Capital:{" "}
					{datosPrevisionales.capital}%, Cuprum: {datosPrevisionales.cuprum}%,
					Habitat: {datosPrevisionales.habitat}%, Modelo:{" "}
					{datosPrevisionales.modelo}%, Planvital:{" "}
					{datosPrevisionales.planvital}%, Provida: {datosPrevisionales.provida}
					%, Uno {datosPrevisionales.uno}%. Para afiliados dependientes,
					independientes y voluntarios. Fuente: Superintendencia de Pensiones.
				</p>
				<span>
					@2021 AFP Modelo. <br /> Todos los Derechos Reservados
				</span>
			</div>
		</footer>
	);
};

export default Footer;
