import React from "react";
import logo from "../assets/img/Logo - Positivo.svg";

const Header = () => (
    <header className="navbar sticky-top navbar-dark bg-dark">
        <div className="align">
            <img src={logo} alt="logo" width="135px" height="83.91px"  />
        </div>

    </header>
);

export default Header;