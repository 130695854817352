import {Card} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import React from "react";

const TablaAporteApv = (props) => {

    const columns_resumen = [{
        dataField: 'id',
        text: '#'
    },{
        dataField: 'fecha',
        text: 'Fecha',
        sort: true
    }, {
        dataField: 'ahorroVoluntarioModelo',
        text: `Dinero ahorro voluntario AFP Modelo`
    }, {
        dataField: 'diferenciaComisionModelo',
        text: 'Diferencia en comision destinada para ahorro en AFP Modelo'
    }, {
        dataField: 'diferenciaComisionPositivaCompara',
        text: `Diferencia de comisión en pesos a favor de AFP ${props.afp}`
    }, {
        dataField: 'diferenciaComisionPositivaModelo',
        text: 'Diferencia de comisión en pesos a favor de AFP Modelo'
    }, {
        dataField: 'comisionEnPesosCompara',
        text: `Comisión en pesos AFP ${props.afp}`
    }, {
        dataField: 'comisionEnPesosModelo',
        text: 'Comisión en pesos AFP Modelo'
    }
    ]

    const datosAporteApv = props.datosAporteApv !== undefined && Array.from(props.datosAporteApv);

    const datos_resumen = datosAporteApv.map((cuota, index) => {
        return {
            id: index,
            fecha: cuota.fecha,
            comisionEnPesosModelo: cuota.comisionEnPesosModelo,
            comisionEnPesosCompara: cuota.comisionEnPesosCompara,
            diferenciaComisionModelo: cuota.diferenciaComisionModelo,
            diferenciaComisionPositivaModelo: cuota.diferenciaComisionPositivaModelo,
            diferenciaComisionPositivaCompara: cuota.diferenciaComisionPositivaCompara,
            ahorroVoluntarioModelo: cuota.ahorroVoluntarioModelo
        }
    });

    return (
        <Card className={"mx-auto w-94 mt-5"}>
            <Card.Body>
                <Card.Title>Detalle Resultados Aporte Regimen A</Card.Title>
                <BootstrapTable
                    bootstrap4
                    keyField='id'
                    data={ datos_resumen }
                    columns={ columns_resumen }
                    striped
                    hover
                    condensed
                    pagination={ paginationFactory()}
                />

            </Card.Body>
        </Card>
    )
}

export default TablaAporteApv;