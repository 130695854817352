import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import Solicitud from './containers/solicitud';
import Resultado from './containers/resultado';
import Simulacion from './containers/simulacion'
import Index from './containers/index';
import './assets/styles/globals.css';
import './assets/styles/index.css';
//import TagManager from 'react-gtm-module'
//const tagManagerArgs = {
//    gtmId: ''
//}
//TagManager.initialize(tagManagerArgs)

const App = function () {
    return (
        <>
            <Header />
            <Router>
                <Switch>
                    <Route path='/' exact component={Index} />
                    <Route path='/resultado' component={Resultado} />
                    <Route path='/solicitud' exact component={Solicitud} />
                    <Route path='/simulacion' component={Simulacion} />
                </Switch>
            </Router>

            <Footer />
        </>
    );
}

export default App;