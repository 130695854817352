import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import contacto from "../assets/svg/contacto.svg";
import arrow from "../assets/svg/arrow.svg";
import "../assets/styles/solicitud.css";
import {
	aacento,
	eacento,
	iacento,
	oacento,
	exclamacion,
} from "../utils/caracteresUTF8";

export default function solicitud() {
	return (
		<>
			<Helmet>
				<title>
					Que AFP te Conviene | Simula cuanto hubieses ahorrado en AFP Modelo |
					AFP Modelo
				</title>
				<meta
					name="description"
					content={`Aumenta tus ahorros, pagando una menor comisi${oacento}n de AFP. Simula tu aumento de ahorro al cambiarte a AFP Modelo.`}
				/>
				<meta name="robots" content="noindex, follow" />
			</Helmet>
			<div className="solicitud">
				<section>
					<div className="">
						<div className="row w-100 container-padre dudas">
							<div className="col-12 d-flex justify-content-center">
								<div className="container-block">
									<img src={contacto} alt="Contacto Ejecutivo" />
									<div className="txt">
										<div className="container-title">
											<h4>
												{exclamacion}Tu solicitud fue enviada con {eacento}xito!
											</h4>
											<p>
												Muchas gracias por utilizar la calculadora de “Que AFP
												te conviene”, un ejecutivo se contactar{aacento} contigo
												a la brevedad para entregarte toda la asesor{iacento}a
												necesaria.
											</p>
											<div className="d-flex justify-content-center mb-5">
												<a
													type="button"
													className="btn btn-lg btn-block mt-3"
													id="webmodelo"
													href="https://nueva.afpmodelo.cl/"
												>
													Visitar sitio de AFP Modelo
												</a>
											</div>
											<Link to="/resultado">
												<a className="volver">
													{" "}
													<img src={arrow} alt="arrow" />
													Volver atr{aacento}s
												</a>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
