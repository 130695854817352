import {Card} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import React from "react";

const TablaCuotasCompradas = (props) => {

    const columns_resumen = [{
        dataField: 'id',
        text: '#'
    },{
        dataField: 'fecha',
        text: 'Fecha',
        sort: true
    }, {
        dataField: 'cuotasALaFechaComparar',
        text: `Cuotas Acumuladas a la fecha AFP ${props.afp}`
    }, {
        dataField: 'cuotasALaFechaModelo',
        text: 'Cuotas Acumuladas a la fecha AFP Modelo'
    }, {
        dataField: 'cuotasCompradasComparar',
        text: `Cuotas compradas en AFP ${props.afp}`
    }, {
        dataField: 'cuotasCompradasModelo',
        text: 'Cuotas compradas en AFP Modelo'
    }, {
        dataField: 'valorCuotaComparar',
        text: `Valor Cuota AFP ${props.afp}`
    }, {
        dataField: 'valorCuotaModelo',
        text: 'Valor Cuota AFP Modelo'
    }
    ]

    const datosCuotasCompradas = props.datosCuotasCompradas !== undefined && Array.from(props.datosCuotasCompradas);
    
    const datos_resumen = datosCuotasCompradas.map((cuota, index) => {
        return {
            id: index,
            fecha: cuota.fecha,
            valorCuotaModelo: cuota.valorCuotaModelo,
            valorCuotaComparar: cuota.valorCuotaComparar,
            cuotasCompradasModelo: cuota.cuotasCompradasModelo,
            cuotasCompradasComparar: cuota.cuotasCompradasComparar,
            cuotasALaFechaModelo: cuota.cuotasALaFechaModelo,
            cuotasALaFechaComparar: cuota.cuotasALaFechaComparar
        }
    });

    return (
        <Card className={"mx-auto w-94 mt-5"}>
            <Card.Body>
                <Card.Title>Detalle Resultados Cuotas Compradas AFPs</Card.Title>
                <BootstrapTable
                    bootstrap4    
                    keyField='id' 
                    data={ datos_resumen } 
                    columns={ columns_resumen }
                    striped
                    hover
                    condensed
                    pagination={ paginationFactory()}
                />

            </Card.Body>
        </Card>
    )
}

export default TablaCuotasCompradas;