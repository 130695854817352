import React, { useState } from "react";
import { Form, Formik, Field } from "formik";
import joven2 from "../assets/svg/joven2.svg"
import Stepper from 'react-stepper-horizontal';
import TerminosModal from "./TerminosModal";
import {aacento, iacento} from "../utils/caracteresUTF8";

export const Wizard = ({ children, initialValues, onSubmit }) => {

    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);

    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children);
    const [snapshot, setSnapshot] = useState(initialValues);

    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const next = values => {
        setSnapshot(values);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const handleSubmit = async (values, bag) => {

        if (step.props.onSubmit) {
            await step.props.onSubmit(values, bag);
        }
        
        if (isLastStep) {
            setIsLoading(true);
            return onSubmit(values, bag);

        } else {
            bag.setTouched({});
            next(values);
        }
        
    };

    const idButton = stepNumber === 1 ? 'calcular' : 'comenzar';
    const textButton = stepNumber === 1 ? 'Calcular' : 'Comenzar';

    

    return (
        <div className="row">
            <div className="bloque form d-flex containerForm">
                <div className="card formulario">
                    <div>
                        <Stepper
                            steps={[{ title: '' }, { title: '' }  ]}
                            activeStep={stepNumber}
                            activeColor={"#72B500"}
                            completeColor={"#72B500"}
                        />
                    </div>
                    <div className="card-body">
                        
                        <p>Conoce que AFP m{aacento}s te conviene:</p>
                        <Formik
                            initialValues={snapshot}
                            onSubmit={handleSubmit}
                            validationSchema={step.props.validationSchema}
                        >
                            {(formik) => (


                                <Form>
                                    {step}

                                    <div className="d-flex justify-content-center flex-column">
                                        <div className="form-check">
                                            <Field name="terminosycondiciones">
                                                {({
                                                      field,
                                                      form: { touched, errors },
                                                  }) => (
                                                    <>
                                                        <label
                                                            className="form-check-label mt-auto"
                                                            htmlFor="terminosycondiciones"
                                                        >
                                                            <input
                                                                {...field}
                                                                type="checkbox"
                                                                className="form-check-input"
                                                            />
                                                            <small className="terminosycondiciones">
                                                                Acepto las{" "}
                                                                <a
                                                                    onClick={handleShow}
                                                                    className="modal-wizard text-wrap"
                                                                >
                                                                    condiciones del servicio y la pol{iacento}
                                                                    tica de privacidad.
                                                                </a>
                                                            </small>
                                                        </label>
                                                        <small
                                                            id="terminosAyuda"
                                                            className={
                                                                `form-text ${touched.terminosycondiciones && errors.terminosycondiciones ? 'is-invalid' : ''}`}
                                                        >
                                                            {touched.terminosycondiciones && errors.terminosycondiciones ? errors.terminosycondiciones : ""}
                                                        </small>
                                                        <TerminosModal
                                                            show={modalShow}
                                                            onHide={handleClose}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col justify-content-center d-flex">
                                            {isLoading
                                                ? <>
                                                    <button className="btn btn-lg btn-block" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                                                    </button>
                                                </>
                                                :
                                                <button
                                                    type="submit"
                                                    id={idButton}
                                                    className="btn btn-lg btn-block purple"
                                                    disabled={!(formik.isValid && formik.dirty)}
                                                >
                                                    {textButton}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </Form>

                            )}
                        </Formik>
                    </div>
                </div>
                <div className="d-flex justify-content-center desktop jovenModelo">
                {<img src={joven2} alt="joven modelo" />}
            </div>
            </div>
        </div>
    );
};

export const WizardStep = ({ children }) => children;
