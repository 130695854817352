import React, { useState, useEffect } from "react";
import { Field } from "formik";
import MaskedInput from "react-text-mask";
import { useHistory } from "react-router-dom";
import { Wizard, WizardStep } from "./WizardComponent";
import { iacento, oacento } from "../utils/caracteresUTF8";
import { celularMask, dineroMask, rutMask } from "../utils/inputMask";
import { Step1Schema, Step2Schema } from "../utils/validationSchemaWizard";
import {
  apiAfp,
  apiIniciar,
  apiConfigurar,
  apiRun,
  apiResultado,
} from "../utils/Services";

export const APVForm = () => {
  const initialValues = {
    nombre: "",
    rut: "",
    correo: "",
    celular: "",
    sueldo: "",
    multifondo: "",
    afp: "",
    terminosycondiciones: false,
    periodo: "",
  };
  const history = useHistory();
  const [afpLista, setAfpLista] = useState([]);
  const [selectedAfp, setSelectedAfp] = useState(0);
  const [idSesion, setIdSesion] = useState(null);
  const [errorAfp, setErrorAfp] = useState(false);

  const [idSimulacion, setIdSimulacion] = useState(null);
  const [runId, setRunId] = useState(null);

  const [token, setToken] = useState(null);
  const [periodos, setPeriodos] = useState([]);

  let afpSelec = "";
  const afpHandleChange = (afp) => {
    if (afpSelec != afp.value) {
      afpSelec = afp.value;
      setSelectedAfp(afp.value);
    }
  };
  useEffect(() => {
    apiAfp().then((afpList) => {
      setAfpLista(afpList);
    });
  }, []);

  useEffect(() => {
    let cantPeriodos = 10;
    if (selectedAfp == 6) {
      cantPeriodos = 3;
      setErrorAfp(false);
    }
    if (selectedAfp == "default") {
      setErrorAfp(true);
    }
    let periodosAux = [];
    for (var i = 1; i <= cantPeriodos; i++) {
      periodosAux.push({
        anios: i + (i === 1 ? " Año" : " Años"),
        meses: i * 12,
      });
    }

    setPeriodos(periodosAux);
  }, [selectedAfp]);

  useEffect(async () => {
    if (idSimulacion) {
      await apiRun(idSimulacion, token).then((runResp) => {
        setRunId(runResp);
      });
    }
  }, [idSimulacion]);

  useEffect(() => {
    if (runId) {
      apiResultado(idSimulacion, runId, token).then((ResultResp) => {
        localStorage.setItem("simulacion", JSON.stringify(ResultResp));
        history.push("/resultado");
      });
    }
  }, [runId]);

  const handleStep1 = (values) => {
    apiIniciar(values).then((iniciarRes) => {
      setIdSesion(iniciarRes.idSesion);
      localStorage.setItem("token", JSON.stringify(iniciarRes.token));
      setToken(iniciarRes.token);
    });
  };
  const submit = (values) => {
    apiConfigurar(values, token, idSesion).then((ConfRes) => {
      if (ConfRes.isSuccess) {
        setIdSimulacion(ConfRes.data);
      }
    });
  };

  return (
    <div className="wizardForm">
      <Wizard
        initialValues={initialValues}
        onSubmit={(values) => submit(values)}
      >
        <WizardStep
          onSubmit={(e) => handleStep1(e)}
          validationSchema={Step1Schema}
        >
          <Field name="nombre">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <label htmlFor="nombre" className="control-label">
                  Nombre completo
                </label>

                <input
                  {...field}
                  type="text"
                  className={`form-control form-control-lg 
                                        ${
                                          meta.touched
                                            ? meta.error
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                  id="nombre"
                  aria-describedby="nombreAyuda"
                  placeholder="Nombre completo"
                />
                <small
                  id="nombreAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error
                    ? meta.error
                    : "Indicanos tu nombre y apellido."}
                </small>
              </div>
            )}
          </Field>
          <Field name="rut">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <label htmlFor="rut" className="control-label">
                  RUT
                </label>
                <MaskedInput
                  {...field}
                  type="text"
                  mask={rutMask}
                  className={`form-control form-control-lg 
                                    ${
                                      meta.touched
                                        ? meta.error
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                  id="rut"
                  aria-describedby="rutAyuda"
                  placeholder="RUT"
                />
                <small
                  id="rutAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej. 12.345.678-9."}
                </small>
              </div>
            )}
          </Field>
          <Field name="correo">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <label htmlFor="correo" className="control-label">
                  Correo
                </label>
                <input
                  {...field}
                  type="email"
                  className={`form-control form-control-lg 
                                        ${
                                          meta.touched
                                            ? meta.error
                                              ? "is-invalid"
                                              : "is-valid"
                                            : ""
                                        }`}
                  id="correo"
                  aria-describedby="correoAyuda"
                  placeholder={`Correo electr${oacento}nico`}
                />
                <small
                  id="correoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error
                    ? meta.error
                    : "Ej. queafpteconviene@afpmodelo.cl"}
                </small>
              </div>
            )}
          </Field>
          <Field name="celular">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <label htmlFor="celular" className="control-label">
                  Celular
                </label>
                <MaskedInput
                  {...field}
                  type="text"
                  mask={celularMask}
                  className={`form-control form-control-lg 
                                    ${
                                      meta.touched
                                        ? meta.error
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                  id="celular"
                  aria-describedby="celularAyuda"
                  placeholder={`9 ____ ____`}
                />
                <small
                  id="celularAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error ? meta.error : "Ej. 9 1112 23XX"}
                </small>
              </div>
            )}
          </Field>
        </WizardStep>
        <WizardStep
          onSubmit={() => console.log("Step2 onSubmit")}
          validationSchema={Step2Schema}
        >
          <Field name="sueldo">
            {({ field, meta }) => (
              <div className="form-group input-wrapper">
                <label htmlFor="sueldo" className={"control-label"}>
                  sueldo
                </label>
                <MaskedInput
                  {...field}
                  type="text"
                  mask={dineroMask}
                  className={`form-control form-control-lg 
                                    ${
                                      meta.touched
                                        ? meta.error
                                          ? "is-invalid"
                                          : "is-valid"
                                        : ""
                                    }`}
                  id="sueldo"
                  aria-describedby="sueldoAyuda"
                  placeholder={`Sueldo Líquido`}
                />
                <small
                  id="sueldoAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error
                    ? meta.error
                    : `El sueldo l${iacento}quido que recibes luego de los descuentos legales.`}
                </small>
              </div>
            )}
          </Field>

          <Field name="afp">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="afp">Ind{iacento}canos tu AFP actual:</label>
                <select
                  onChange={afpHandleChange(field)}
                  {...field}
                  className="form-control form-control-lg"
                  id="afp"
                  name="afp"

                  //value={selectedAfp}
                >
                  <option value="default">Seleccionar</option>
                  {afpLista.map((afp) => (
                    <option value={afp.id} key={afp.id}>
                      {afp.nombre}
                    </option>
                  ))}
                </select>
                <small
                  id="afpAyuda"
                  className={`form-text ${
                    meta.touched && meta.error ? "is-invalid" : ""
                  }`}
                >
                  {meta.touched && meta.error}
                </small>
              </div>
            )}
          </Field>

          <Field name="multifondo">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="multifondo">
                  Ind{iacento}canos tu Multifondo actual:
                </label>
                <select
                  {...field}
                  className="form-control form-control-lg"
                  id="multifondo"
                  name="multifondo"
                >
                  {" "}
                  <option value="default">Seleccionar</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                </select>
                <small
                  id="multifondoAyuda"
                  className={`form-text 
                                        ${
                                          meta.touched && meta.error
                                            ? "is-invalid"
                                            : ""
                                        }`}
                >
                  {meta.touched && meta.error}
                </small>
              </div>
            )}
          </Field>

          <Field name="periodo">
            {({ field, meta }) => (
              <div className="form-group input-wrapper select">
                <label htmlFor="periodo">Per{iacento}odo a simular:</label>
                <select
                  {...field}
                  className="form-control form-control-lg"
                  id="periodo"
                  name="periodo"
                >
                  {" "}
                  <option value="default">Seleccionar</option>
                  {periodos.map((periodo) => (
                    <option value={periodo.meses} key={periodo.meses}>
                      {periodo.anios}
                    </option>
                  ))}
                </select>
                <small
                  id="multifondoAyuda"
                  className={`form-text 
                                        ${
                                          meta.touched && meta.error
                                            ? "is-invalid"
                                            : ""
                                        }`}
                >
                  {meta.touched && meta.error}
                </small>
              </div>
            )}
          </Field>
        </WizardStep>
      </Wizard>
    </div>
  );
};

export default APVForm;
