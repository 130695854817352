import React, { useEffect, useState } from 'react'
import axios from "axios"
import { Helmet } from "react-helmet"
import '../assets/styles/resultados.css'
import info from "../assets/svg/info.svg"
import plus from "../assets/svg/plus.svg"
import { Card, Table } from "react-bootstrap"
import Tooltip from "react-bootstrap/Tooltip"
import { useHistory } from "react-router-dom"
import corazon from "../assets/svg/corazon.svg"
import mujerSAC from "../assets/svg/mujersac.svg"
import montanha from "../assets/svg/montanha.svg"
import { apiResultadoUa, apiSolicitud } from "../utils/Services"
import { sueldoFormateador } from '../utils/validationRut'
import OverlayTrigger from "react-bootstrap/OverlayTrigger"

export default function Resultado() {

    let history = useHistory();
    const [simulacion, setSimulacion] = useState(null)
    const [token, setToken] = useState(null)

    const renderTooltip = (props) => (

        <Tooltip id="button-tooltip" {...props}>
            Este es el monto que tendrías si hubieses ahorrado la diferencia de comisión. Incluye un 15% de
            bonificación fiscal al ahorro en APV Régimen A.
        </Tooltip>
    );

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        let runId = query.get('runId')
        let idSimulacion = query.get('idSimulacion')
        let token = query.get('token')

        if (runId != null && idSimulacion != null && token != null) {
            console.log(runId, idSimulacion, token)
            apiResultadoUa(idSimulacion, runId, token)
                .then((ResultResp) => {
                    console.log(ResultResp)
                    setSimulacion(ResultResp)
                })
                .catch((err) => {
                    console.log(err)
                    history.push("/")
                })
        } else if (JSON.parse(localStorage.getItem('simulacion')) != null) {
            setSimulacion(JSON.parse(localStorage.getItem('simulacion')))
            setToken(JSON.parse(localStorage.getItem('token')))

        } else {
            history.push("/")
        }
    }, []);

    function contactarme() {

        apiSolicitud(token)
        .then((SoliResp) => {
            console.log(SoliResp)
            history.push("/solicitud")
        })
        .catch((err) => {
            console.log(err)
            history.push("/")
        })
    }

    function conocer_apv() {
        window.location.href = "https://www.queapvconviene.cl/";
    }

    const dudas_texto =
        `Nuestros ejecutivos pueden asesorarte en línea o vía teléfonica. Queremos ayudarte a resolver todas tus inquietudes o darte todas las opciones para tu traspaso.`;

    return (
        <>
            <Helmet>
                <title>Que AFP te Conviene | Simula cuanto hubieses ahorrado en AFP Modelo | AFP Modelo</title>
                <meta name="description" content={`Aumenta tus ahorros, pagando una menor comisión de AFP. Simula tu aumento de ahorro al cambiarte a AFP Modelo.`} />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <section className="container">
                {simulacion != null && (
                    <div className="resultado">
                        <div className="row">
                            <div className="col-md-6 col-sm-12 container-top">
                                <p className="col-izq-p textGreen">Habrías ahorrado con Modelo &nbsp;<img src={corazon} alt="corazon" width="36" height="36" /></p>

                                <h2>Hoy tendrías {sueldoFormateador(simulacion.resultadoSinApv)} más.</h2>

                                <span>Este monto corresponde a la diferencia entre la rentabilidad obtenida en tu actual AFP y AFP Modelo, más la diferencia en las comisiones, del periodo simulado.
                                    &nbsp;
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Este monto corresponde a la diferencia entre la rentabilidad obtenida en tu actual AFP y AFP Modelo, más la diferencia en las comisiones, del periodo simulado.
                                            </Tooltip>
                                        }
                                    >
                                        <img className="info" src={info} alt="informacion" />

                                    </OverlayTrigger>
                                </span>

                                <div className='d-flex justify-content-center desktop'>
                                    <a
                                        type="button"
                                        id="cambiate_a_AFP_Modelo"
                                        className="btn btn-lg btn-block greenBtn"
                                        //href={"https://cambiate.afpmodelo.cl/idSesion=" + simulacion.idSesion + "&guidToken=" + simulacion.guidToken}
                                        href="https://cambiate.afpmodelo.cl"
                                    >Cámbiate a AFP Modelo</a>
                                </div>
                            </div>
                            <br />
                            <div className="col-md-6 d-flex flex-column ctn">
                                <Card>
                                    <Card.Body>
                                        <Table responsive
                                            className="table-borderless"
                                        >
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{simulacion.nombreAfpOrigen}</th>
                                                    <th>AFP Modelo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><span className="indicador">Rentabilidad:</span></td>
                                                    <td className="text-right">{sueldoFormateador(simulacion.rentabilidadAfpOrigen)}</td>
                                                    <td className="text-right modelo">{sueldoFormateador(simulacion.rentabilidadAfpModelo)}</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="indicador">Diferencia de rentabilidad:</span></td>
                                                    <td className="text-right">-</td>
                                                    <td className={"text-right modelo " + (simulacion.diferenciaRentabilidad > 0 ? "ahorro" : "diferencia")}>{sueldoFormateador(simulacion.diferenciaRentabilidad)}</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="indicador">Comisión AFP:</span></td>
                                                    <td className="text-right">{sueldoFormateador(simulacion.comisionAfpOrigen)}</td>
                                                    <td className="text-right modelo">{sueldoFormateador(simulacion.comisionAfpModelo)}</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="indicador">Ahorro en comisión:</span></td>
                                                    <td className="text-right">-</td>
                                                    <td className={"text-right modelo " + (simulacion.diferenciaComision > 0 ? "ahorro" : "diferencia")}>{sueldoFormateador(simulacion.diferenciaComision)}</td>
                                                </tr>
                                                <tr>
                                                    <td><span className="indicador">Rentabilidad + comisión:</span></td>
                                                    <td className="text-right">-</td>
                                                    <td className="text-right modelo">{sueldoFormateador(simulacion.resultadoSinApv)}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className='col-sm-12 mobile d-flex justify-content-center'>
                                <a
                                    type="button"
                                    id="cambiate_a_AFP_Modelo"
                                    className="btn btn-lg btn-block greenBtn"
                                    //href={"https://cambiate.afpmodelo.cl/idSesion=" + simulacion.idSesion + "&guidToken=" + simulacion.guidToken}
                                    href="https://cambiate.afpmodelo.cl"
                                >Cámbiate a AFP Modelo</a>
                            </div>
                        </div>
                        {simulacion.diferenciaComision > 1 && (
                            <div className="row">
                                <div className="col-lg-12 d-flex flex-column align-items-center">
                                    <h1 className="new-h1">Si inviertes tu ahorro en comisión. Puedes ganar más. <span><img className='' src={montanha} alt="montanha" /></span></h1>
                                    <span className="parrafos">Ahorrando en APV la diferencia de comisión, puedes hacer crecer tu pensión.</span>
                                    <Card className="card2">
                                        <Card.Body >
                                            <Card.Title>Podrías haber ganado:</Card.Title>
                                            <Card.Text><img src={plus} width="30px" alt="plus" /> {sueldoFormateador(simulacion.resultadoConApv)} &nbsp;
                                                <OverlayTrigger
                                                    delay={{ show: 250, hide: 400 }}
                                                    placement="top"
                                                    overlay={renderTooltip}
                                                >
                                                    <img src={info} alt="informacion" width="35px" />

                                                </OverlayTrigger>
                                                <p className="text-right">
                                                    *Monto obtenido en {(simulacion.cantidadMesesASimular) / 12} año(s).</p>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>

                                    <div className='d-flex justify-content-center'>
                                        <button 
                                        type="button" 
                                        id="Ir_a_que_APV_Conviene" 
                                        className="btn btn-lg btn-block " 
                                        onClick={conocer_apv}>Ir a Que APV me conviene</button>
                                    </div>
                                </div>
                            </div>
                        )}


                        <div className="row container-padre dudas">
                            <div className="ctn col-md-4 d-flex desktop">
                                <img src={mujerSAC} alt="Dudas" />
                            </div>
                            <div className="ctn col-sm-12 col-md-7 d-flex flex-column contenedor">
                                <div className="parrafo2">
                                    <div className="container-title">
                                        <h2>¿Aún tienes dudas?</h2>
                                        <p>{dudas_texto}</p>
                                        <div className='col-sm-12 d-flex justify-content-center'>
                                            <button
                                                type="button"
                                                id="Quiero_que_me_contacten"
                                                className="btn btn-lg btn-block whiteBtn"
                                                onClick={contactarme}>
                                                Quiero que me contacten
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row like '>
                            <div className='ctn col-12 d-flex justify-content-center'>
                                <p>Si te gusto esta simulación te invitamos a ser parte de &nbsp; <span className="text-decoration-underline textGreen">Afp Modelo</span>&nbsp; y ver nuestros productos para ti. </p>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    );
}

