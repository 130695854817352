import {Card} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import React from "react";

 const TablaResumenGeneral = (props) => {

    const columns_resumen = [{
        dataField: 'nombreCampo',
        text: 'Nombre Campo'
    }, {
        dataField: 'resultado',
        text: 'Resultado'
    }
    ];
    
    const datos_resumen = [{
        id: 0,
        nombreCampo: `Rentabilidad AFP ${props.afp}`,
        resultado : props.datosResumen.rentabilidadAfpActual
    }, {
        id: 1,
        nombreCampo: 'Rentabilidad AFP Modelo',
        resultado : props.datosResumen.rentabilidadAfpModelo
    }, {
        id: 2,
        nombreCampo: 'Diferencia de Rentabilidad',
        resultado : props.datosResumen.diferenciaDeRentabilidad
    }, {
        id: 3,
        nombreCampo: 'Ahorro en Comisión (Aporte en Regimen A)',
        resultado : props.datosResumen.ahorroEnComision
    }, {
        id: 4,
        nombreCampo: 'Renta Imponible',
        resultado : props.datosResumen.resntaImponible
    }, {
        id: 5,
        nombreCampo: 'Cuota Inicial',
        resultado : props.datosResumen.cuotaInicial
    }, {
        id: 6,
        nombreCampo: 'Bonificacion Fiscal Regimen A',
        resultado : props.datosResumen.apvBonificacionFiscal
    }, {
        id: 7,
        nombreCampo: 'Aporte APV Total',
        resultado : props.datosResumen.apvTotalApv
    },{
        id: 8,
        nombreCampo: 'Monto Cuotas Modelo',
        resultado : props.datosResumen.ahorroModelo
    },
        {
            id: 9,
            nombreCampo: `Monto Cuotas ${props.afp}`,
            resultado : props.datosResumen.ahorroComparar
        }]


    return (
        <Card className={"mx-auto w-75 mt-5"}>
            <Card.Body>
                <Card.Title>Resumen Resultados</Card.Title>
                <BootstrapTable keyField='id' data={ datos_resumen } columns={ columns_resumen } />

            </Card.Body>
        </Card>
    )
} 

export default TablaResumenGeneral;