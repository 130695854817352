import axios from "axios";
import { cleanDigitos } from "../utils/cleanInputMask";
import configuration from "../Config";

export const apiAfp = async () => {
	const res = await axios.get(`${configuration.API_URL}/api/Afp`);
	if (res.status === 200) {
		console.log("Success afps");
		return res.data;
	}
	return false;
};
export const apiIniciar = async (values) => {
	let body = {
		rut: values.rut,
		nombreCompleto: values.nombre,
		telefono: cleanDigitos(values.celular),
		email: values.correo,
	};

	const res = await axios.post(`${configuration.API_URL}/api/qac/iniciar`, body);
	if (res.status === 200) {
		console.log("Success Iniciar");
		return res.data.data;
	}
	return false;
};

export const apiConfigurar = async (values, token, idSesion) => {
	const headers = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	let body = {
		idSesion: idSesion,
		sueldoLiquido: cleanDigitos(values.sueldo),
		cantidadMesesADeclarar: values.periodo,
		idAfpOrigen: values.afp,
		fondo: values.multifondo,
	};
	const res = await axios.post(
		`${configuration.API_URL}/api/qac/configurar`,
		body,
		headers
	);
	if (res.status === 200) {
		console.log("Success configurar");
		return res.data;
	}
	return false;
};

export const apiRun = async (idSimulacion, token) => {
	var data;
	await fetch(`${configuration.API_URL}/api/qac/run/${idSimulacion}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	})
		.then((response) => response.json())
		.then(async (result) => {
			if (result.isSuccess === true) {
				console.log("Success Run");
				data = result.data;
			}
		})
		.catch((error) => {
			console.log("Success Run");
		});

	return data;
};

export const apiResultado = async (idSimulacion, runId, token) => {
	const header = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	const res = await axios.get(
		`${configuration.API_URL}/api/qac/resultado?idSimulacion=${idSimulacion}&runId=${runId}&enviarEmail=true`,
		header
	);
	if (res.status === 200) {
		console.log("Success Resultado");
		return res.data;
	}
	return false;
};

export const apiSolicitud = async (token) => {
	const header = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
	};
	console.log(token);
	const res = await axios.post(
		`${configuration.API_URL}/api/qac/solicitudcontacto`,
		null,
		header
	);
	if (res.status === 200) {
		console.log("Success apiSolicitud");
		console.log(res.data);
		return res.data;
	}
	return false;
};

export const apiResultadoUa = async (idSimulacion, runId, token) => {
	console.log(runId, idSimulacion, token);

	const res = await axios.get(
		`${configuration.API_URL}/api/qac/resultadoua?idSimulacion=${idSimulacion}&runId=${runId}&token=${token}`
	);
	if (res.status === 200) {
		console.log("Success ResultadoUa");
		return res.data;
	}
	return false;
};
