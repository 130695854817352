import React from "react";
import QacSimular from "../components/QacSimular";
import '../assets/styles/solicitud.css';

export default function solicitud() {
    
    

    return (
        <>
            <div className="solicitud" >
                <section>
                    <QacSimular />
                </section>
            </div>
        </>
    );
}