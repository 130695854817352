import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import shapeBkg from "../assets/svg/mobiles/backgroundMobile.svg";
import APVForm from "../components/ApvForm";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { oacento } from "../utils/caracteresUTF8";

export default function Home() {
  const [token, setToken] = useState("");
  const idSesion = uuidv4();

  useEffect(() => {
    const obtenerToken = async () => {
      const response = await axios.get("api/Token/GenerateToken");
      const bearer = await response.data.key;

      setToken(bearer);
    };
    //obtenerToken();
  }, []);

  localStorage.setItem("bearer", token);
  localStorage.setItem("sessionId", idSesion);

  return (
    <div className="container home">
      <Helmet>
        <title>
          Que AFP te Conviene | Simula cuanto hubieses ahorrado en AFP Modelo |
          AFP Modelo
        </title>
        <meta
          name="description"
          content="Aumenta tus ahorros, eligiendo la AFP que te conviene. Simula tu aumento de ahorro al cambiarte a AFP Modelo."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <div>
        <section>
          <div className="row w-100 container-padre calculadora">
            <div className="col-12 d-flex justify-content-center mobile">
              <div className="container-title">
                <h1>
                  Compara tu afp con AFP Modelo,
                  <br />
                  <span className="green">
                    y descubre cuál te conviene más.
                  </span>
                </h1>
                <br></br>
                <br></br>
              </div>
            </div>
            <div className="col-12 d-flex mobile">
              <p className="txtMobileShape">
                La rentabilidad y la comisión pueden hacer una gran diferencia
                en tu pensión. Mira cuánto podrías haber ahorrado en AFP Modelo.
              </p>
              <img
                src={shapeBkg}
                className="background "
                alt="compara tu AFP"
              />
            </div>
            <div className="bloque desktop">
              <div className="txtDesktop">
                <div className="container-title">
                  <h1>
                    Compara tu afp con AFP Modelo,
                    <br />
                    <span className="green">
                      y descubre cuál te conviene más.
                    </span>
                  </h1>
                  <p>
                    La rentabilidad y la comisión pueden hacer una gran
                    diferencia en tu pensión. Mira cuánto podrías haber ahorrado
                    en AFP Modelo.
                  </p>
                </div>
              </div>
            </div>
            <div className="bloque form">
              <APVForm token={token} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
