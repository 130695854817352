import * as Yup from "yup";
import {iacento, sueldoMinimo, sueldoMinimoString} from "./caracteresUTF8";

export const SchemaVal = Yup.object({
    sueldo: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]{6,8}$/, `Ingrese un sueldo l${iacento}quido superior a $${sueldoMinimoString}.`)
        .test('Sueldo-validacion', `Ingrese un sueldo l${iacento}quido superior a  $${sueldoMinimoString}.`, function (value) {
            return (value >= sueldoMinimo)
        })
        .required(`Por favor ingrese su sueldo l${iacento}quido.`),
    ahorro: Yup
        .string()
        .transform(value => value.replace(/[^\d]/g, ''))
        .matches(/^[0-9]+$/, `Ingrese el monto en pesos que tienes ahorrado en tu cuenta obligatoria.`)
        .test('Sueldo-validacion', `Ingrese un monto desde $0.`, function (value) {
            return (value >= 0)
        })
        .required('Por favor ingrese el monto que tiene ahorrado en su cuenta obligatoria.'),
    afp: Yup
        .string()
        .test('afp-validacion', 'Por favor seleccione su AFP', function (afp){
            return (afp !== 'default')
        })
        .required('Por favor seleccione su AFP'),
    multifondo: Yup
        .string()
        .test('multifondo-validacion', 'Por favor seleccione su Multifondo', function (multifondo){
            return (multifondo !== 'default')
        })
        .required('Por favor seleccione su multifondo'),
    retiro10Porciento: Yup
        .string()
        .test('retiro10Porciento-validacion', 'Por favor indique si realizo un retiro', function (retiro10Porciento){
            return (retiro10Porciento !== 'default')
        })
        .required('Por favor indique si realizo un retiro')
});