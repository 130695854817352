import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import axios from 'axios';
import MaskedInput from "react-text-mask";
import { Card, Col, Row } from "react-bootstrap";

import { iacento, oacento } from "../utils/caracteresUTF8";
import { cleanDigitos } from "../utils/cleanInputMask";
import { dineroMask } from "../utils/inputMask";

import {SchemaVal} from "../utils/validationSchema";

import TablaResumenGeneral from "./TablaResumenGeneral";
import TablaCuotasCompradas from "./TablaCuotasCompradas";
import TablaRentabilidad from "./TablaRentabilidad";
import TablaAporteApv from "./TablaAporteApv";

export default function QacSimular() {


    const [datosResumen, setDatosResumen] = useState({});
    const [datosCuotasCompradas, setDatosCuotasCompradas] = useState({});
    const [datosRentabilidadAfp, setDatosRentabilidadAfp] = useState({});
    const [datosAporteApv, setDatosAporteApv] = useState({});
    const [afp, setAfp] = useState('0');

    const initialValues = {
        sueldo: '',
        ahorro: '',
        afp:'',
        multifondo: '',
        retiro10Porciento: '',
    }
    
    const [isLoading, setIsLoading] = useState(false);
    const [initial, setInitial] = useState(true);
  
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={SchemaVal}
                onSubmit={(values) => {
                    setIsLoading(true);
                    setInitial(false);
                    setAfp(values.afp);

                    const headers = {
                        "Content-Type": "application/json",
                        
                    };

                    const url = `Qac/simular`;

                    let tipoRetiro1 = false;
                    let tipoRetiro2 = false;
                    let retiro10 = false;

                    switch(values.retiro10Porciento) {
                        case '1':
                            retiro10 = true;
                            tipoRetiro1 = true;
                            break;
                        case '2':
                            retiro10 = true;
                            tipoRetiro2 = true;
                            break;
                        case "3":
                            retiro10 = true;
                            tipoRetiro1 = true;
                            tipoRetiro2 = true;
                            break;
                        default:
                            break;
                    }

                    const body= {
                        nombreCompleto: 'Simular',
                        rut: '1-9',
                        correo: 'simulacion@qac.modelo.cl',
                        numeroTelefonico: '956565656',
                        aceptaCondicionesDeUso: true,
                        sueldoLiquido: parseInt(cleanDigitos(values.sueldo)),
                        montoAhorrado: parseInt(cleanDigitos(values.ahorro)),
                        idAfp: parseInt(values.afp),
                        multifondo: values.multifondo,
                        retiro10Porciento: retiro10,
                        tipoRetiro1: tipoRetiro1,
                        tipoRetiro2: tipoRetiro2
                    };


                    console.log("Envio de datos del formulario");
    
    
                    axios.post(url, body, { headers: headers }).then((response) => {
    
                        let data = response.data;

                        setDatosResumen({
                            rentabilidadAfpActual: data["rentabilidadAfpActual"],
                            rentabilidadAfpModelo: data["rentabilidadAfpModelo"],
                            diferenciaDeRentabilidad: data["diferenciaDeRentabilidad"],
                            ahorroEnComision: data["ahorroEnComision"],
                            resntaImponible: data["resntaImponible"],
                            cuotaInicial: data["cuotaInicial"],
                            apvBonificacionFiscal: data["apvBonificacionFiscal"],
                            apvTotalApv: data["apvTotalApv"],
                            ahorroModelo: data["ahorroModelo"],
                            ahorroComparar: data["ahorroComparar"]
                        });
                        setDatosCuotasCompradas(data["desglose"]["calculoAhorroAfp1"]);
                        setDatosRentabilidadAfp(data["desglose"]["calculoAhorroAfp2"]);
                        setDatosAporteApv(data["desglose"]["calculoAhorroVol1"]);
                        
                        setIsLoading(false);
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }}
            >
                {(formik) => (

                    <Card className={"mx-auto w-75 mt-5"}>
                        <Card.Header className={"mx-auto nu-header"}>Ingresa tus datos y lo calcularemos para ti:</Card.Header>
                        <Card.Body>
                            <Form>
                                <div className="form-group input-wrapper">
                                    <Row>
                                        <Col>
                                            <Field name="sueldo"
                                            >
                                                {({
                                                      field,
                                                      meta
                                                  }) => (
                                                    <div className="form-group input-wrapper">
                                                        <label
                                                            htmlFor="sueldo"
                                                            className={"control-label"}>sueldo
                                                        </label>
                                                        <MaskedInput
                                                            {...field}
                                                            type="text"
                                                            mask={dineroMask}
                                                            className={`form-control form-control-lg 
                                    ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                                            id="sueldo"
                                                            aria-describedby="sueldoAyuda"
                                                            placeholder={`Sueldo L${iacento}quido`}
                                                            autocomplete="off"
                                                        />
                                                        <small
                                                            id="sueldoAyuda"
                                                            className={`form-text ${meta.touched && meta.error ? "is-invalid" : ""}`}
                                                        >
                                                            {meta.touched && meta.error
                                                                ? meta.error
                                                                : `El sueldo l${iacento}quido que recibes luego de los descuentos legales.`
                                                            }
                                                        </small>
                                                    </div>
                                                )}
                                            </Field>
                                        </Col>
                                        <Col>
                                            <Field name="ahorro">
                                                {({
                                                      field,
                                                      meta
                                                  }) => (
                                                    <div className="form-group input-wrapper">
                                                        <label
                                                            htmlFor="ahorro"
                                                            className="control-label"
                                                        >
                                                            Monto Ahorrado
                                                        </label>
                                                        <MaskedInput
                                                            {...field}
                                                            type="text"
                                                            mask={dineroMask}
                                                            className={`form-control form-control-lg 
                                    ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                                            id="ahorro"
                                                            aria-describedby="ahorroAyuda"
                                                            placeholder="Monto Ahorrado"
                                                            autocomplete="off"
                                                        />
                                                        <small
                                                            id="ahorroAyuda"
                                                            className={`form-text ${meta.touched && meta.error ? "is-invalid" : ""}`}
                                                        >
                                                            {meta.touched && meta.error
                                                                ? meta.error
                                                                : `El monto que tienes actualmente en tu cuenta obligatoria.`
                                                            }
                                                        </small>
                                                    </div>
                                                )}
                                            </Field>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Field name="afp">
                                                {({
                                                      field,
                                                      meta
                                                  }) => (
                                                    <div className="form-group input-wrapper select">
                                                        <label htmlFor="afp">
                                                            Ind{iacento}canos tu AFP actual:
                                                        </label>
                                                        <select
                                                            {...field}
                                                            className="form-control form-control-lg"
                                                            id="afp"
                                                            name="afp"
                                                        >
                                                            {" "}
                                                            <option value="default">
                                                                Seleccione una AFP
                                                            </option>
                                                            <option value="0">AFP Capital</option>
                                                            <option value="1">AFP Cuprum</option>
                                                            <option value="2">AFP Habitat</option>
                                                            <option value="3">AFP PlanVital</option>
                                                            <option value="4">AFP Provida</option>
                                                            <option value="6">AFP Uno</option>
                                                        </select>
                                                        <small
                                                            id="afpAyuda"
                                                            className={`form-text ${meta.touched && meta.error ? "is-invalid" : ""}`}
                                                        >
                                                            {meta.touched && meta.error}
                                                        </small>
                                                    </div>
                                                )}
                                            </Field>
                                        </Col>
                                        <Col>
                                            <Field name="multifondo">
                                                {({
                                                      field,
                                                      meta
                                                  }) => (
                                                    <div className="form-group input-wrapper select">
                                                        <label htmlFor="multifondo">
                                                            Ind{iacento}canos tu Multifondo actual:
                                                        </label>
                                                        <select
                                                            {...field}
                                                            className="form-control form-control-lg"
                                                            id="multifondo"
                                                            name="multifondo"
                                                        >
                                                            {" "}
                                                            <option value="default">
                                                                Seleccione un Multi Fondo
                                                            </option>
                                                            <option value="A">A</option>
                                                            <option value="B">B</option>
                                                            <option value="C">C</option>
                                                            <option value="D">D</option>
                                                            <option value="E">E</option>
                                                        </select>
                                                        <small
                                                            id="multifondoAyuda"
                                                            className={`form-text 
                                        ${meta.touched && meta.error ? "is-invalid" : ""}`}
                                                        >
                                                            {meta.touched && meta.error}
                                                        </small>
                                                    </div>
                                                )}
                                            </Field>
                                        </Col>
                                        <Col>
                                            <Field name="retiro10Porciento">
                                                {({
                                                      field,
                                                      meta
                                                  }) => (
                                                    <div className="form-group input-wrapper select">
                                                        <label htmlFor="retiro10Porciento">
                                                            Ind{iacento}canos si realizaste el retiro del 10%:
                                                        </label>
                                                        <select
                                                            {...field}
                                                            className="form-control form-control-lg"
                                                            id="retiro10Porciento"
                                                            name="retiro10Porciento"
                                                        >
                                                            {" "}
                                                            <option value="default">
                                                                Indique si realizó el retiro
                                                            </option>
                                                            <option value="0">No retir{oacento}</option>
                                                            <option value="1">Primer Retiro</option>
                                                            <option value="2">Segundo Retiro</option>
                                                            <option value="3">Ambos Retiros</option>
                                                        </select>
                                                        <small
                                                            id="retiro10PorcientoAyuda"
                                                            className={`form-text 
                                        ${meta.touched && meta.error ? "is-invalid" : ""}`}
                                                        >
                                                            {meta.touched && meta.error}
                                                        </small>
                                                    </div>
                                                )}
                                            </Field>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="col justify-content-center d-flex">
                                        {isLoading
                                            ? <>
                                                <button className="btn btn-lg btn-block" type="button" disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                </button>
                                            </>
                                            :
                                            <button
                                                type="submit"
                                                id="calcular"
                                                className="btn btn-lg btn-block"
                                                disabled={!(formik.isValid && formik.dirty && formik.values.afp !== '-1')}
                                            >
                                                Calcular
                                            </button>
                                        }

                                    </div>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>

                )}

            </Formik>
            
            {initial || (!initial && isLoading) ? 
                <>
                    
                </> : 
                <>
                    <TablaResumenGeneral
                        datosResumen={datosResumen}
                        afp={obtener_afp(afp)}
                    />

                    <TablaCuotasCompradas
                        datosCuotasCompradas={datosCuotasCompradas}
                        afp={obtener_afp(afp)}
                    />

                    <TablaRentabilidad
                        datosRentabilidadAfp={datosRentabilidadAfp}
                        afp={obtener_afp(afp)}
                    />

                    <TablaAporteApv
                        datosAporteApv={datosAporteApv}
                        afp={obtener_afp(afp)}
                    />
                </>}
        </>
        
    );
}

function obtener_afp(afp) {
    let nombre_afp = ''
    switch (afp) {

        case '0':
            nombre_afp = 'Capital'
            break;
        case '1':
            nombre_afp = 'Cuprum'
            break;
        case '2':
            nombre_afp = 'Habitat'
            break;
        case '3':
            nombre_afp = 'PlanVital'
            break;
        case '4':
            nombre_afp = 'ProVida'
            break;
        case '6':
            nombre_afp = 'Uno'
            break;
        default:
            break;
    }
    
    return nombre_afp;
}

